:root {
  --color-primary: #B9E7E4;
  --color-primary-light: rgba(185, 231, 228, 0.4);
  --color-secondary: #EDF2F7;
  --color-dark: #0D1D18;
  --color-text-light: #718096;

  --sidebar-width: 288px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* #menu .active::after {
  content: '';
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid white;
  position: absolute; right: 0; top: 0;
} */
